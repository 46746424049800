/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, Dispatch, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {DashboardClient, DashboardQueryParams} from '../../../infrastracture/api/DashboardClient'
import Moment from 'react-moment'
import moment from 'moment'
import {Link, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {RootState} from '../../../../setup'
import {Actions} from '../../../modules/work-order/redux/WorkOrderRedux'

type Props = {
  dispatch: Dispatch<any>
  workorder: any
  tenantId: string
}

const ActiveWorkOrdersByCompany: React.FC<Props> = (props) => {
  const {workorder, dispatch, tenantId} = props
  const [itemsList, setItemsList] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (tenantId) fetchData()

    //fetch data every 2 mins
    const interval = setInterval(() => {
      fetchData()
    }, 120000)

    return () => clearInterval(interval)
  }, [tenantId])

  const fetchData = async () => {
    setLoading(true)
    var query = new DashboardQueryParams()

    var response: any

    if (tenantId !== 'all') {
      response = await DashboardClient.GetActiveWorkOrdersByCompany(
        query.Paginate(0, 99999).WithAssignedTo(tenantId).WithTenantId(tenantId)
      )
    } else {
      response = await DashboardClient.GetActiveWorkOrdersByCompany(query.Paginate(0, 99999))
    }

    if (!response.authenticated) {
    } else {
      const {data} = response
      setItemsList(data.data)
    }
    setLoading(false)
  }

  const addDefaultSrc = (ev: any) => {
    ev.target.src = toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm004.svg')
  }

  return (
    <div className={`card `}>
      <div className={`card-header border-0 py-5`}>
        <h3 className='card-title fw-bolder'>Active Work Orders</h3>

        <div className='card-toolbar'>
          <span className='badge badge-primary'>
            {itemsList?.reduce((a, {count}) => a + count, 0) ?? 0}
          </span>
        </div>
      </div>

      <div className='card-body pt-2' style={{height: '500px', overflowY: 'auto'}}>
        {loading && (
          <div className='d-flex align-items-center'>
            <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
          </div>
        )}

        {!loading && !itemsList.length && (
          <div className='fw-bolder text-gray-800 ps-3 text-center'>No Data</div>
        )}

        {!loading &&
          itemsList.length > 0 &&
          itemsList.map((item: any) => (
            <div className='d-flex align-items-sm-center mb-7'>
              <div className='symbol symbol-50px me-5'>
                <span className='symbol-label'>
                  <img
                    className='image-input-wrapper w-50px h-50px'
                    src={item.logo}
                    onError={addDefaultSrc}
                  ></img>
                </span>
              </div>
              <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                <div className='flex-grow-1 me-2'>
                  <span
                    style={{cursor: 'pointer'}}
                    className='text-gray-800 text-hover-primary fw-bolder fs-6'
                    onClick={() => {
                        dispatch(Actions.setFilter({
                            ...workorder.filter, clientId: item.tenantId, workOrderStatusId: null,
                            ignoreComplete: false,
}))
                      history.push('/work-orders/list')
                    }}
                  >
                    {item.name}
                  </span>
                  <span className='text-muted fw-bold d-block fs-7'>
                    <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                      {item.oldest}
                    </Moment>
                  </span>
                </div>
                <span className='badge badge-light fw-bolder my-2'>{item.count}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default connect(({workorder}: RootState) => ({
  workorder,
}))(ActiveWorkOrdersByCompany)
